import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateBusinessSettings } from "services/profile/profile.service";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import SettingsInput from "./SettingsInput";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import { VERIFICATION_MODES } from "constants/verification";
import { useSelector } from "react-redux";
import _ from "lodash";

function TextMessages(props) {
  const { settings, plan } = props;
  const { register, handleSubmit, errors } = useForm();
  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);
  const [verifyAlert, setVerifyAlert] = useState(false);
  const [pendingAlert, setPendingAlert] = useState(false);
  const remoteConfig = useSelector(state => state.config || {});
  const { account } = useSelector(state => state.account || {});
  const [isVerifyRequired, setIsVerifyRequired] = useState(false);
  const [isPendingVerification, setIsPendingVerification] = useState(false);
  const [isCustomizeRestricted, setIsCustomizeRestricted] = useState(false);

  const [form, setForm] = useState({
    welcomeText: "",
    sendWelcomeText: false,
    notificationText: "",
    sendWalkoutText: false,
    walkoutText: "",
    reservationNotificationText: "",
    reservationWalkoutText: ""
  });

  useEffect(() => {
    setIsVerifyRequired(
      account?.plan &&
        account.plan !== "free" &&
        (account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.UNVERIFIED ||
          account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.FAILED) &&
        remoteConfig?.verificationMode === VERIFICATION_MODES.ON
    );
    setIsPendingVerification(
      account?.plan &&
        account.plan !== "free" &&
        (account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.PENDING ||
          account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED) &&
        remoteConfig?.verificationMode === VERIFICATION_MODES.ON
    );
    setIsCustomizeRestricted(
      (remoteConfig?.verificationMode && account?.plan && account?.plan === "free") ||
        (shouldVerify(account) && account.phoneConfig?.messagesRestricted)
    );
  }, [remoteConfig, account]);

  const getRemoteConfigText = text => {
    const textToReturn = remoteConfig?.[text];
    return (props.businessName ? textToReturn?.replace("{location}", props.businessName) : textToReturn) || "";
  };

  useEffect(() => {
    if (settings) {
      setForm({
        welcomeText: isCustomizeRestricted ? getRemoteConfigText("textWelcome") : settings.welcomeText || "",
        sendWelcomeText: settings.sendWelcomeText || false,
        notificationText: isCustomizeRestricted ? getRemoteConfigText("textNotify") : settings.notificationText || "",
        sendWalkoutText: settings.sendWalkoutText || false,
        walkoutText: isCustomizeRestricted ? getRemoteConfigText("textWalkout") : settings.walkoutText || "",
        reservationNotificationText: isCustomizeRestricted
          ? getRemoteConfigText("textReservationNotify")
          : settings.reservationNotificationText || "",
        reservationWalkoutText: isCustomizeRestricted
          ? getRemoteConfigText("textReservationWalkout")
          : settings.reservationWalkoutText || ""
      });
    }
    // eslint-disable-next-line
  }, [settings, remoteConfig, isCustomizeRestricted]);

  const hideVerifyAlert = () => {
    setVerifyAlert(false);
  };

  const hidePendingAlert = () => {
    setPendingAlert(false);
  };

  const shouldVerify = account => {
    return (
      account?.plan !== "free" &&
      !(account?.phoneConfig?.status === "verified" || account?.phoneConfig?.status === "submitted") &&
      remoteConfig?.verificationMode === VERIFICATION_MODES.ON
    );
  };

  const showVerifyBusinessModal = () => {
    hideVerifyAlert();
    window.location.replace("#" + document.URL.split("#")[1] + "&action=verify");
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const isFreeAccount = !plan || plan === "free";

  const handleTextareaChange = key => event => {
    let value;
    if (typeof event === "string") {
      value = event;
    } else {
      value = event.target.value;
    }

    if (isFreeAccount) {
      return;
    }
    setForm({ ...form, [key]: value });
  };

  const onSubmit = async data => {
    const payload = { ...form, ...data };
    await updateBusinessSettings(
      props.businessId,
      isCustomizeRestricted
        ? _.omit(payload, [
            "welcomeText",
            "notificationText",
            "walkoutText",
            "reservationNotificationText",
            "reservationWalkoutText"
          ])
        : payload
    );
    toastr.success(`Text messages successfully updated`);
  };

  const hideUpgradeAlert = () => {
    setShowUpgradeAlert(false);
  };

  const displayUpgradeAlert = () => {
    if (isFreeAccount) {
      setShowUpgradeAlert(true);
      return;
    }
    if (isVerifyRequired) {
      setVerifyAlert(true);
      return;
    }
    if (isPendingVerification) {
      setPendingAlert(true);
      return;
    }
  };

  const onGoToUpgrade = () => {
    hideUpgradeAlert();
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };

  return (
    <SettingsWrapper
      title={`Text Messages ${props.businessName ? " - " + props.businessName : ""}`}
      handleSubmit={handleSubmit(onSubmit)}
      buttonStyles={{ maxWidth: 670 }}
    >
      {verifyAlert && (
        <SweetAlert
          showCancel
          title={"Business Verification Required"}
          cancelBtnText="Cancel"
          confirmBtnText="Verify Now"
          onCancel={hideVerifyAlert}
          onConfirm={showVerifyBusinessModal}
        >
          In order to customize text messages, you'll need to verify your business.
        </SweetAlert>
      )}
      {pendingAlert && (
        <SweetAlert title={"Business Verification Pending"} confirmBtnText="Ok" onConfirm={hidePendingAlert}>
          Once we are able to verify your business, you will be able to customize your text messages.
        </SweetAlert>
      )}
      {showUpgradeAlert && (
        <SweetAlert
          showCancel
          title={"Want to customize your text messages?"}
          cancelBtnText="Cancel"
          confirmBtnText="Upgrade"
          onCancel={hideUpgradeAlert}
          onConfirm={onGoToUpgrade}
        >
          Upgrade to any paid plan to customize your text messages.
        </SweetAlert>
      )}
      <Row>
        <Col md={6}>
          <SettingsInput
            form={form}
            enabled={true}
            name={"sendWelcomeText"}
            type={"switch"}
            value={form.sendWelcomeText}
            label={"Send Welcome Texts"}
            register={null}
            onChangeValue={toggleChecked}
          />
        </Col>
      </Row>
      {isFreeAccount && (
        <Row>
          <Col md={10}>
            <p className="upgrade-plan-message">
              To customize your text messages <Link to="/admin/upgrade">upgrade</Link> your plan
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={10}>
          <SettingsInput
            form={form}
            name={"welcomeText"}
            type={"textarea"}
            errors={errors}
            enabled={form.sendWelcomeText}
            value={form.welcomeText}
            label={"Waitlist Welcome Text"}
            link={form.sendWelcomeText && !isFreeAccount && !isVerifyRequired}
            subtitle="Sent when Someone is added to the waitlist"
            register={register}
            onChangeValue={handleTextareaChange}
            onInputClick={form.sendWelcomeText ? displayUpgradeAlert : null}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <SettingsInput
            form={form}
            name={"notificationText"}
            type={"textarea"}
            errors={errors}
            enabled={true}
            value={form.notificationText}
            label={"Waitlist Notification Text"}
            subtitle={"Sent when the blue notify button is clicked on waitlist screen"}
            register={register}
            link={!isFreeAccount && !isVerifyRequired}
            onChangeValue={handleTextareaChange}
            onInputClick={form.notificationText ? displayUpgradeAlert : null}
          />
        </Col>
      </Row>
      {settings && settings.enableReservations && (
        <Row>
          <Col md={10}>
            <SettingsInput
              form={form}
              name={"reservationNotificationText"}
              type={"textarea"}
              errors={errors}
              enabled={true}
              value={form.reservationNotificationText}
              label={"Reservation Notification Text"}
              subtitle={"Sent when the blue notify button is clicked on reservations screen"}
              register={register}
              onChangeValue={handleTextareaChange}
              onInputClick={displayUpgradeAlert}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6}>
          <SettingsInput
            form={form}
            name={"sendWalkoutText"}
            type={"switch"}
            enabled={true}
            value={form.sendWalkoutText}
            label={'Send "No Show" Texts'}
            register={null}
            onChangeValue={toggleChecked}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <SettingsInput
            form={form}
            name={"walkoutText"}
            type={"textarea"}
            errors={errors}
            enabled={form.sendWalkoutText}
            value={form.walkoutText}
            label={'Waitlist "No Show" Text'}
            subtitle={"Sent when you mark someone as 'No Show'"}
            register={register}
            onChangeValue={handleTextareaChange}
            onInputClick={form.sendWalkoutText ? displayUpgradeAlert : null}
          />
        </Col>
      </Row>
      {settings && settings.enableReservations && (
        <Row>
          <Col md={10}>
            <SettingsInput
              form={form}
              name={"reservationWalkoutText"}
              type={"textarea"}
              errors={errors}
              enabled={form.sendWalkoutText}
              value={form.reservationWalkoutText}
              label={'Reservation "No Show" Text'}
              subtitle={"Sent when someone doesn't show up for a reservation"}
              register={register}
              onChangeValue={handleTextareaChange}
              onInputClick={form.sendWalkoutText ? displayUpgradeAlert : null}
            />
          </Col>
        </Row>
      )}
    </SettingsWrapper>
  );
}

export default TextMessages;
