import React, { useState } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import "./AddGuests.scss";
import { useForm } from "react-hook-form";
import { getAverageWaitForTimePeriod } from "services/visits/visits.service";
import { Business } from "types/business";
import { formatPhone } from "helpers/phone.helper";
import { BuildCusmomizableInputsForm } from "components/Settings/CustomizableInputFiels/BuildCustomizableInputFields";
import { useMediaQuery } from "hooks/useMediaQuery";
import CustomButton from "components/CustomButton/CustomButton";
import { handleEnterKeyNextField } from "helpers/form.helper";

export type AddGuestForm = {
  name: string;
  partySize?: number;
  quotedTime?: string;
  phone?: string;
  service?: string;
  orderNumber?: string;
  email?: string;
  note?: string;
};

type IForm = AddGuestForm;

type AddGuestModalProps = {
  handleGuestFormClose: (form?: any) => Promise<void>;
  showGuestForm: boolean;
  guestForm: any;
  allVisitors: any;
  business: Business;
};

function AddGuestModal(props: AddGuestModalProps) {
  const { showGuestForm, handleGuestFormClose, guestForm, allVisitors, business } = props;
  const { register, handleSubmit, errors } = useForm<IForm>();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const getDefaultPartySize = () => {
    return business.settings.defaultPartySize || 1;
  };

  const emptyForm = {
    name: "",
    partySize: getDefaultPartySize() || 1,
    quotedTime: "",
    phone: "",
    service: "",
    orderNumber: "",
    email: "",
    note: ""
  };

  const getCategoryInitialValue = () => {
    if (guestForm && guestForm.data() && guestForm.data().service) {
      return guestForm.data().service;
    }
    return "";
  };

  const [form, setForm] = useState<AddGuestForm>({
    name: guestForm && guestForm.data() ? guestForm.data().name : "",
    partySize: guestForm && guestForm.data() ? parseInt(guestForm.data().partySize) : getDefaultPartySize(),
    quotedTime: guestForm && guestForm.data() ? guestForm.data().quotedTime.toString() : "",
    phone: guestForm && guestForm.data() ? guestForm.data().phone : "",
    orderNumber: guestForm && guestForm.data() ? guestForm.data().orderNumber : "",
    email: guestForm && guestForm.data() ? guestForm.data().email : "",
    note: guestForm && guestForm.data() ? guestForm.data().note : "",
    service: getCategoryInitialValue()
  });

  const onSubmit = data => {
    handleGuestFormClose({ ...data, phone: formatPhone(data.phone) });
    setForm(emptyForm);
  };

  const closeModal = () => {
    handleGuestFormClose();
    setForm(emptyForm);
  };

  return (
    <Modal dialogClassName="add-guest-modal" show={showGuestForm} onHide={closeModal}>
      {isMobile && (
        <CustomButton className="sign-up-modal-wrapper__close-btn" simple onClick={closeModal}>
          <i className="fa fa-times" />
        </CustomButton>
      )}
      <Card
        textCenter={true}
        title={form.name === "" ? "Add Guest" : "Edit Guest"}
        content={
          <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)} onKeyDown={handleEnterKeyNextField}>
            <div className="flex">
              <div className="add-guest__stats">
                <div className="add-guest__stats-label">Average Wait</div>
                <div className="add-guest__stats-value">{getAverageWaitForTimePeriod(allVisitors)}</div>
              </div>
              <div className="add-guest__stats">
                <div className="add-guest__stats-label">Last Estimate</div>
                <div className="add-guest__stats-value">
                  {allVisitors && allVisitors.length ? allVisitors[allVisitors.length - 1].data().quotedTime : 0} mins
                </div>
              </div>
            </div>
            <BuildCusmomizableInputsForm<AddGuestForm>
              form={form}
              register={register}
              errors={errors}
              business={business}
              setForm={setForm}
              type="waitlist"
            />
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {guestForm && guestForm.data() ? "Save Guest" : "Add Guest"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      ></Card>
    </Modal>
  );
}

export default AddGuestModal;
