export const FIREBASE_EVENTS = {
  visit_add: "visit_add",
  visit_update: "visit_update",
  visit_walkout: "visit_walkout",
  visit_notify_success: "visit_notify_success",
  visit_notify_too_soon: "visit_notify_too_soon",
  visit_done: "visit_done",
  visit_delete: "visit_delete",
  visit_notify_no_phone: "visit_notify_no_phone",
  sign_out: "sign_out",
  sign_up: "sign_up",
  sign_in: "sign_in",
  square_connect: "square_connect",
  square_setup_complete: "square_setup_complete",
  square_start_setup: "square_start_setup",
  begin_checkout: "begin_checkout",
  add_payment_info: "add_payment_info",
  visit_edit: "visit_edit",
  forgot_password: "forgot_password",
  message_view: "message_view",
  message_quick_response: "message_quick_response",
  message_custom: "message_custom",
  intro_tour_activate: "intro_tour_activate",
  intro_tour_activate_success: "intro_tour_activate_success",
  intro_tour_registration: "intro_tour_registration",
  intro_tour_quick_start: "intro_tour_quick_start",
  landing_page_view: "landing_page_view",
  landing_page_show_signup_step1: "landing_page_show_signup_step1",
  landing_page_show_signup_step2: "landing_page_show_signup_step2",
  show_trial_offers: "show_trial_offers",
  activate_without_card: "activate_without_card",
  activate_with_card: "activate_with_card"
};
