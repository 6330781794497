/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

// image for avatar in Sidebar
import waitlyLogo from "../../assets/img/waitly_white_logo.svg";
import waitlyLogoTrunc from "../../assets/img/waitly_white_logo_trunc.svg";

import routes from "../../routes";
import { useSelector } from "react-redux";
import { displayCustomers, displayDashboard, hideSettings } from "configs/access";
import { displayCharts } from "configs/access";

import AppStoreIcon from "../../assets/img/bug-app-store.svg?react";
import classes from "./Sidebar.module.scss";
import "./Sidebar.scss";
import { useMediaQuery } from "hooks/useMediaQuery";
import cx from "classnames";

var ps;

function Sidebar(props) {
  const isSidebarVisibleForHovering = useMediaQuery("(min-width: 992px)");
  const account = useSelector(state => state.account.account);
  const prices = useSelector(state => state.prices.prices);
  // var [account, loading] = useCollectionDataOnce(
  // db.collection("accounts").doc(props.user.accountId)
  // );

  const [isHovered, setIsHovered] = useState(false);
  var [width, setWidth] = useState(window.innerWidth);
  const businessSelector = useSelector(state => state.business);
  const { business } = businessSelector;
  useEffect(() => {
    updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
      if (ps) {
        ps.destroy();
      }
    };
  }, []);

  const sidebarWrapper = useCallback(node => {
    if (navigator.platform.indexOf("Win") > -1) {
      if (node) {
        ps = new PerfectScrollbar(node, {
          suppressScrollX: true,
          suppressScrollY: false
        });
      }
    }
  }, []);

  const createLinks = routes => {
    return routes
      .filter((prop, key) => prop.name !== "Pages" && prop.name !== "Profile" && prop.name !== "Subscription Details")
      .map((prop, key) => {
        if (prop.name === "Dashboard" && account && !displayDashboard(account.plan, props.user)) {
          return null;
        }
        if (prop.name === "Charts" && account && !displayCharts(account.plan, props.user)) {
          return null;
        }
        if (prop.name === "Customers" && account && !displayCustomers(account.plan, props.user, account)) {
          return null;
        }
        if (prop.name === "Reservations" && !(business && business.settings && business.settings.enableReservations)) {
          return null;
        }
        if (prop.name === "Settings" && hideSettings(props.user, account)) {
          return null;
        }
        if (
          prop.name === "Upgrade" &&
          ((account && ((account.plan === "pro" && account.autoPay) || account.plan === "enterprise")) ||
            props.user.claims.role === "analyst")
        ) {
          return null; // acc not free, not premium
        }
        return (
          <li className={activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink
              to={prop.layout + prop.path}
              className="nav-link"
              activeClassName="active"
              onClick={() => {
                if (isSidebarVisibleForHovering) {
                  setIsHovered(false);
                }
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p>{getItemName(prop)}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{getItemName(prop)}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      });
  };

  const getItemName = prop => {
    if (prop.name === "Upgrade" && prices && prices.trialEligible && account && account.plan === "free") {
      return "Start Free Trial";
    } else {
      return prop.name;
    }
  };

  const getPlanName = () => {
    if (account && account.plan) {
      return account.plan.charAt(0).toUpperCase() + account.plan.slice(1);
    }
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div
      className={cx({
        sidebar: true,
        hovered: isHovered || !props.mini
      })}
      data-color={props.color}
      data-image={props.image}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.hasImage ? (
        <div className="sidebar-background" style={{ backgroundImage: "url(" + props.image + ")" }} />
      ) : (
        ""
      )}
      <div className="logo">
        <a href="/" className="simple-text logo-mini">
          <div style={{ marginLeft: "100px" }}></div>
          <div className="logo-img">
            <img className="logo-full" src={waitlyLogo} alt="waitly-logo" />
            <img className="logo-trunc" src={waitlyLogoTrunc} alt="waitly-logo" />
          </div>
        </a>
        <p className="plan-description">| {getPlanName()}</p>
      </div>
      <div className="sidebar-wrapper" ref={sidebarWrapper}>
        {business ? (
          <ul className="nav">
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {createLinks(routes)}
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {width <= 992 ? <AdminNavbarLinks {...props} /> : null}
          </ul>
        ) : null}
      </div>
      <div className={`${classes.AppStoreBtnWrapper} fade-app-store-icon`}>
        <a href="https://apps.apple.com/app/apple-store/id1437729300?pt=1717824&ct=app.waitly&mt=8" target="_blank">
          <AppStoreIcon className={classes.AppStoreBtn} />
        </a>
        <span className={classes.AppStoreText}>Download the App</span>
      </div>
    </div>
  );
}

export default Sidebar;
