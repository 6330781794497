import React, { useEffect } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import WaitlistTable from "components/WaitlistTable/WaitlistTable";
import { Subject } from "rxjs";
import { restoreVisitorToWaitlist } from "services/visits/visits.service";
import { useSelector } from "react-redux";

function History(props) {
  const visitSelector = useSelector(state => state.visit);
  const { historyVisitors } = visitSelector;
  const business = useSelector(state => state.business.business)

  useEffect(() => {
    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  });
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <WaitlistTable
              history={true}
              user={props.user}
              business={business}
              guestList={historyVisitors}
              restoreVisitorToWaitlist={restoreVisitorToWaitlist}
            ></WaitlistTable>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default History;
