import React from "react";
import "./WaitlistFilter.scss";
import Select from "react-select";

function WaitlistFilter(props) {
  const { filter, setFilter, business } = props;
  let categories = [];
  if (business.settings && business.settings.services && business.settings.services.length) {
    categories = business.settings.services.map(service => {
      return {
        value: service.name,
        label: service.name
      };
    });
  }

  const parties = [
    { value: "1-2", label: "1-2" },
    { value: "3-4", label: "3-4" },
    { value: "5", label: "5+" }
  ];

  const customStyles = {
    container: (_, { selectProps: { width } }) => ({
      width,
      marginRight: "5px"
    })
  };

  const changeFilter = (type, event) => {
    setFilter({ ...filter, [type]: event });
  };

  const resetFilter = () => {
    setFilter({ partySize: null, category: null });
  };

  return (
    <div className="wl-filter">
      <div className="wl-filter__row">
        <Select
          onChange={changeFilter.bind(this, "partySize")}
          styles={customStyles}
          placeholder="Party size"
          value={filter.partySize}
          options={parties}
          width="120px"
          isSearchable={false}
        />
        {business.settings && business.settings.services && business.settings.services.length ? (
          <Select
            onChange={changeFilter.bind(this, "category")}
            styles={customStyles}
            placeholder="Preference"
            value={filter.category}
            options={categories}
            width="120px"
            isSearchable={false}
          />
        ) : null}
      </div>
      {filter.partySize || filter.category ? (
        <div className="wl-filter__filter-details">
          <div className="wl-filter__column">Waitlist filtered.</div>
          <div className="wl-filter__clear" onClick={resetFilter}>
            Show All
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default WaitlistFilter;
