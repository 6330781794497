import React, { useEffect } from "react";
import StatsCard from "components/Card/StatsCard.jsx";
import { VisitStatus } from "services/visits/visits.service";
import "./WaitlistStats.scss";
import moment from "moment";
import { DateTime } from "luxon";
import { useMediaQuery } from "hooks/useMediaQuery";

function WaitlistStats(props) {
  const { waitingVisitors, allVisitors, isDashboard, dashboardStats, businesses } = props;

  const isToday = visit => {
    if (visit.data().checkInTime) {
      return moment(new Date()).diff(visit.data().checkInTime.toDate(), "days") < 1;
    }
    return false;
  };

  const calculateDashboardStatsValue = value => {
    if (!dashboardStats || !businesses) {
      return 0;
    }
    let result = 0;

    dashboardStats.forEach(stat => {
      const b = businesses[stat.data().businessId];
      if (b) {
        result += stat.data()[value];
      }
    });
    if (result < 0) {
      return 0;
    }
    return result;
  };

  const getPartiesSize = () => {
    if (isDashboard) {
      return calculateDashboardStatsValue("partiesWaiting");
    } else {
      return waitingVisitors.length;
    }
  };

  const getStatus = type => {
    if (!isDashboard && !allVisitors) {
      return 0;
    }
    if (isDashboard) {
      let status;
      if (type === VisitStatus.seated) {
        status = "served";
      }
      if (type === VisitStatus.walkout) {
        status = "walkedout";
      }
      return calculateDashboardStatsValue(status);
    }
    return allVisitors.reduce((accum, visit) => {
      if (visit.data().status === Number(type) && isToday(visit)) {
        accum += visit.data().partySize || 1;
      }
      return accum;
    }, 0);
  };

  const getWaitingCount = () => {
    if (isDashboard) {
      return calculateDashboardStatsValue("waiting");
    } else {
      return waitingVisitors.reduce((accum, visit) => {
        accum += visit.data().partySize;
        return accum;
      }, 0);
    }
  };

  const getAverageTime = () => {
    const fifteen = DateTime.local().minus({ minutes: 15 });
    if (isDashboard) {
      if (!dashboardStats || !businesses) {
        return;
      }
      let count = 0;
      let dateAverage = 0;
      dashboardStats.forEach(stat => {
        const b = businesses[stat.data().businessId];
        if (b) {
          stat.data().last30MinutesServed.forEach(el => {
            dateAverage += el.minutesWaited;
            count++;
          });
        }
      });
      return `${Math.round(dateAverage / (count || 1))} mins`;
    } else {
      let count = 0;
      let dateAverage = 0;
      const dashboardStat = dashboardStats.find(ds => ds.data().businessId === props.businessId);
      if (dashboardStat) {
        dashboardStat.data().last30MinutesServed.forEach(stat => {
          if (DateTime.fromJSDate(stat.servedTime.toDate()) >= fifteen) {
            dateAverage += stat.minutesWaited;
            count++;
          }
        });
      }
      return `${Math.round(dateAverage / (count || 1))} mins`;
    }
  };

  const [stats, setStats] = React.useState({
    waiting: getWaitingCount(),
    averageWait: getAverageTime(),
    served: getStatus(VisitStatus.seated),
    noShows: getStatus(VisitStatus.walkout)
  });

  const [updateTime, setUpdateTime] = React.useState(Date.now());

  useEffect(() => {
    setStats({
      waiting: getWaitingCount(),
      averageWait: getAverageTime(),
      served: getStatus(VisitStatus.seated),
      noShows: getStatus(VisitStatus.walkout)
    });
  }, [waitingVisitors, allVisitors, dashboardStats, businesses, updateTime]);

  useEffect(() => {
    const interval = setInterval(() => setUpdateTime(Date.now()), 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="stats-responsive-wrapper">
      <div>
        <StatsCard
          bigIcon={<i className="mdi mdi-account-clock text-warning" />}
          statsText="Waiting"
          statsValue={stats.waiting?.toLocaleString("en-US")}
          statsIcon={<i className="mdi mdi-account-multiple" />}
          statsIconText={getPartiesSize() + " parties"}
        />
      </div>
      <div>
        <StatsCard
          bigIcon={<i className="mdi mdi-clock text-info" />}
          statsText="Average Wait"
          statsValue={stats.averageWait?.toLocaleString("en-US")}
          statsIcon={<i className="fa fa-calendar-o" />}
          statsIconText="Last 15 minutes"
        />
      </div>
      {!isMobile && (
        <>
          <div>
            <StatsCard
              bigIcon={<i className="mdi mdi-account-multiple text-success" />}
              statsText="Served"
              statsValue={stats.served?.toLocaleString("en-US")}
              statsIcon={<i className="fa fa-clock-o" />}
              statsIconText="Today"
            />
          </div>
          <div>
            <StatsCard
              bigIcon={<i className="mdi mdi-run-fast text-danger" />}
              statsText="No Shows"
              statsValue={stats.noShows?.toLocaleString("en-US")}
              statsIcon={<i className="fa fa-refresh" />}
              statsIconText="Today"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default WaitlistStats;
