import React from "react";
import { Grid, Col, Row } from "react-bootstrap";
import WaitlistStats from "components/WaitlistStats/WaitlistStats";
import DashboardTable from "components/DashboardTable/DashboardTable";
import { useEffect } from "react";
import { getAccountById } from "services/account/account.service";
import { useState } from "react";
import { useSelector } from "react-redux";
import MultiSelectFilter from "components/MultiSelectFilter/MultiSelectFilter";

function Dashboard(props) {
  const aggregateSelector = useSelector(state => state.aggregate);
  const visitSelector = useSelector(state => state.visit);
  const businesses = useSelector(state => state.business.businesses);
  const regions = useSelector(state => {
    return state.regions.regions;
  });
  const { dashboardStats } = aggregateSelector;
  const { waitingVisitors } = visitSelector;
  const [account, setAccount] = useState(null);
  const [userBusiness, setUserBusiness] = useState(null);
  const [areaValues, setAreaValues] = useState([]);
  const [regionValues, setRegionValues] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState(businesses);
  const [businessesMap, setBusinessesMap] = useState();
  const [filterLoading, setFilterLoading] = useState(true);
  const [regionLoading, setRegionLoading] = useState(true);
  const [dashboardFilterOptions, setDashboardFilterOptions] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const isRegionalManager = props.user.claims.role === "regional-manager";

  const ifRegionalThanFilter = options => {
    return isRegionalManager
      ? options.filter(opt => opt.value === businesses.find(b => props.user.businessId === b.businessId).parentRegion)
      : options;
  };

  useEffect(() => {
    const loadAccount = async () => {
      const account = await getAccountById(props.user.accountId);
      setAccount(account);
    };
    loadAccount();
  }, [props.user.accountId]);

  useEffect(() => {
    if (businesses) {
      setUserBusiness(businesses.find(b => props.user.businessId === b.businessId));
      let buses = businesses;

      if (areaValues && areaValues.length) {
        buses = businesses.filter(
          business => business.dashboardEnabled === true && selectedAreas.some(area => area.value === business.regionId)
        );
      }

      let businessesMap = {};
      buses.forEach(b => {
        businessesMap[b.businessId] = b;
      });
      setBusinessesMap(businessesMap);
      setFilteredBusinesses(buses);
    }
  }, [businesses, selectedAreas]);

  useEffect(() => {
    setRegionLoading(true);
    if (regions) {
      const areas = regions
        .filter(r => r.isArea)
        .map(r => {
          return { value: r.id, label: r.name, parentRegion: r.parentRegion };
        });
      setAreaValues(areas);
      const allRegions = regions
        .filter(r => !r.isArea)
        .map(r => {
          return { value: r.id, label: r.name };
        });
      setRegionValues(allRegions);
    }
    setRegionLoading(false);
  }, [regions]);

  const onFilter = selectedOptions => {
    let options = ifRegionalThanFilter(selectedOptions);
    const areas = options.map(so => so.children.filter(c => c.checked)).flat();
    setSelectedAreas(areas);
  };

  React.useEffect(() => {
    if (regionLoading || !areaValues || !areaValues.length) return undefined;
    setFilterLoading(true);
    const filterOptions = [...regionValues.map(r => ({ ...r, checked: true, children: [] }))];
    filterOptions.push({ value: "without-region", label: "Without Region", checked: true, children: [] });
    areaValues.forEach(a => {
      let parentIndex = filterOptions.findIndex(o => o.value === a.parentRegion);
      const isWithParent = parentIndex > -1;
      parentIndex = isWithParent ? parentIndex : filterOptions.length - 1;
      filterOptions[parentIndex].children.push({
        ...a,
        checked: true,
        parentRegion: isWithParent ? a.parentRegion : "without-region"
      });
    });
    setDashboardFilterOptions(ifRegionalThanFilter(filterOptions));

    const filtersFromLS = JSON.parse(localStorage.getItem("dashboard_filters"));
    if (filtersFromLS) {
      const values = [...filterOptions];
      const newFilteredValues = values.map(parent => {
        return {
          ...parent,
          children:
            parent?.children.map(child => {
              return {
                ...child,
                checked:
                  filtersFromLS.find(fls => fls.value === parent.value)?.children.find(c => c.value === child.value)
                    ?.checked ??
                  (child?.checked || true)
              };
            }) || []
        };
      });

      newFilteredValues.forEach((p, parentIndex) => {
        let isAllChildrenChecked = true;
        let isAllChildrenUnChecked = true;

        newFilteredValues[parentIndex].children.forEach(c => {
          c.checked ? (isAllChildrenUnChecked = false) : (isAllChildrenChecked = false);
        });

        if (isAllChildrenChecked) newFilteredValues[parentIndex].checked = true;
        else if (isAllChildrenUnChecked || !isAllChildrenChecked) {
          newFilteredValues[parentIndex].checked = false;
        }
      });

      setDashboardFilterOptions(ifRegionalThanFilter(newFilteredValues));
      onFilter(newFilteredValues);
    } else onFilter(filterOptions);
    setFilterLoading(false);
  }, [regionLoading]);

  return (
    <div className="dashboard main-content">
      <div className="stats-container">
        {waitingVisitors ? (
          <WaitlistStats
            isDashboard={true}
            dashboardStats={dashboardStats}
            businesses={businessesMap}
            user={props.user}
          />
        ) : (
          ""
        )}
        {(props.user.claims.role === "admin" ||
          props.user.claims.role === "regional-manager" ||
          props.user.claims.role === "analyst") && (
          <>
            {!filterLoading && (
              <MultiSelectFilter
                showOnlyChidren={isRegionalManager}
                onFilter={onFilter}
                options={dashboardFilterOptions}
                filteredText="Dashboard filtered."
              />
            )}
          </>
        )}

        <div>
          {userBusiness && businesses && businesses.length && dashboardStats && account ? (
            <DashboardTable user={props.user} businesses={filteredBusinesses} dashboardStats={dashboardStats} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
