import BeatLoader from "react-spinners/BeatLoader";
import { useState } from "react";
import { stripePortal, checkout } from "../../services/profile/profile.service";
import PriceHelper from "helpers/PriceHelper";

function PriceBox({ plan, interval, account, prices, exitPoint }) {
  const [startingSession, setStartingSession] = useState(false);
  const ph = new PriceHelper(prices);

  const FEATURES_LIST = {
    premium: {
      name: "Premium",
      title: "Great for getting started",
      features: [
        { text: "1 Location", bold: true },
        { text: "1,000 parties / month", bold: true },
        { text: "Personalize text messages", bold: false },
        { text: "Customer self check-In", bold: false },
        { text: "Reservations in app", bold: false },
        { text: "Custom branding", bold: false },
        { text: "In app support", bold: false }
      ]
    },
    pro: {
      name: "Pro",
      title: "Most Popular for High Traffic",
      features: [
        { text: "3 Locations", bold: true },
        { text: "4,000 parties / month", bold: true },
        { text: "Everything in Premium PLUS:", bold: true },
        { text: "Reservations from your website", bold: false },
        { text: "Website widgets", bold: false },
        { text: "Mulitple locations", bold: false },
        { text: "Real-time dashboard", bold: false },
        { text: "Separate logins for each location", bold: false }
      ]
    },
    enterprise: {
      name: "Enterprise",
      title: "Perfect for large chains",
      features: [
        { text: "Unlimited Locations", bold: true },
        { text: "Volume Pricing", bold: true },
        { text: "Access to all features", bold: true },
        { text: "Flexible Terms", bold: false },
        { text: "Service Level Agreement (SLA)", bold: false }
      ]
    }
  };

  const isDisabled = () => {
    return account && account.origin === "ios" && account.autoPay && plan !== "enterprise";
  };

  const getButtonText = () => {
    if (plan === "enterprise") {
      return "Contact Us";
    } else if (
      account &&
      ["pro", "premium"].includes(account.plan) &&
      plan === account.plan &&
      account.autoPay === false
    ) {
      return "Restart Plan";
    } else if (account && account.plan === "premium" && plan === "pro") {
      return "Upgrade Now";
    } else if (ph.isTrialEligible()) {
      return "Start Free Trial";
    } else {
      return "Start Now";
    }
  };

  const upgradeClicked = async type => {
    if (plan === "enterprise") {
      return window.open("https://www.waitly.com/contact-us", "_blank");
    }
    setStartingSession(true);
    if (account.origin === "stripe" && account.plan == "premium") {
      const response: any = await stripePortal();
      if (response) {
        const json = await response.json();
        window.location.href = json.url;
      }
    } else {
      const checkoutResponse: any = await checkout(type, interval, exitPoint);
      if (checkoutResponse) {
        const json: any = await checkoutResponse.json();
        window.location.href = json.url;
      }
    }
  };
  return (
    <div className="col-xs-12 pricing-section__main-box">
      <div className="pricing-section__box">
        <div className="pricing-section__box-header">
          <div className="pricing-section__box-header-left">{FEATURES_LIST[plan].name}</div>
          <div className="pricing-section__box-header-right">
            <span>{ph.getPriceToday(plan, interval)}</span>
            {ph.hasDiscount(plan, interval) && (
              <span style={{ textDecoration: "line-through", fontSize: 18, color: "#999" }}>
                {ph.getPrice(plan, interval)}
              </span>
            )}

            <span className="pricing-section__box-header-right--small">{ph.getSavings(plan, interval)}</span>
          </div>
        </div>
        <h4 className="pricing-section__box-content-title">{FEATURES_LIST[plan].title}</h4>
        <ul className="pricing-section__box-options">
          {FEATURES_LIST[plan].features.map((feature, index) => {
            return (
              <li key={"feature" + plan + index} className="pricing-section__box-line">
                <i className="fa fa-check pricing-section__box-icon check"></i>{" "}
                {feature.bold ? <strong>{feature.text}</strong> : feature.text}
              </li>
            );
          })}
        </ul>
        <button
          className="pricing-section__box-button desktop"
          disabled={isDisabled()}
          onClick={() => upgradeClicked(plan)}
        >
          {startingSession ? (
            // @ts-ignore
            <BeatLoader margin={2.5} size={10} color={"#FFFFFF"} />
          ) : (
            getButtonText()
          )}
        </button>
      </div>
    </div>
  );
}

export default PriceBox;
