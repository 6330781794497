import { firebase } from "../../firebase";
import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import "./LoginPage.scss";
import Card from "components/Card/Card.jsx";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "components/CustomButton/CustomButton";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import { getRedirectToSquareUrls } from "services/square/square.service";
import { Subject } from "rxjs";
import { REGISTER_SOURCES } from "constants/register-sources";

function LoginPage(props) {
  let { location } = props;
  let [cardHidden, setCardHidden] = useState(true);
  const [square, setSquare] = useState(false);
  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    if (
      params.get("source") === REGISTER_SOURCES.SQUARE ||
      params.get("source") === REGISTER_SOURCES.SQUARE_WAITLY ||
      params.get("source") === REGISTER_SOURCES.SQUARE_SUBSCRIPTION
    ) {
      setSquare(true);
    }
    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, [props.location.search]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCardHidden(false);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Grid>
      <Row>
        <Col>
          <LoginForm
            square={square}
            hidden={cardHidden}
            reserveWithGoogle={
              location.state &&
              location.state.initialHash === "#/admin/settings?page=reservations&reserveWithGoogle=true"
            }
            upgrade={location.state && location.state.initialHash === "#/admin/upgrade"}
          />
        </Col>
      </Row>
    </Grid>
  );
}

function LoginForm({ square, hidden, upgrade, reserveWithGoogle }) {
  const getTitle = () => {
    if (upgrade) {
      return "Sign in to see upgrade options";
    }
    if (reserveWithGoogle) {
      return "Sign in to turn on Reserve with Google";
    }

    return "Sign In";
  };

  const authErrorMessages = {
    "auth/network-request-failed": "Unable to connect, check your internet connection.",
    "auth/invalid-email": "Email address was not valid",
    "auth/too-many-requests": "Too many tries, try again later."
  };
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleLogin = async event => {
    event.preventDefault();
    setAuthError(undefined);
    setLoading(true);
    let email = event.target.elements[0].value;
    let password = event.target.elements[1].value;
    if (email && password) {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        if (square) {
          const urls = await getRedirectToSquareUrls();
          window.location.href = urls.signin;
        }
        logEvent(FIREBASE_EVENTS.sign_in);
      } catch (error) {
        error.displayMessage = authErrorMessages[error.code] || "Invalid Email or password.";
        setAuthError(error);
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ paddingTop: "100px", textAlign: "center" }}>
      <Grid className="login-form">
        <Row className="show-grid" style={{ maxWidth: "100vw" }}>
          <Col xs={12} md={12}>
            <h2>Welcome Back</h2>
            {square ? <h4>Sign in to link your Square account</h4> : <h4>Sign in to get started</h4>}
          </Col>
        </Row>
        <Row style={{ maxWidth: "100vw" }}>
          <Col xs={12} md={12}>
            <Form onSubmit={handleLogin}>
              <Card
                hidden={hidden}
                textCenter
                title={getTitle()}
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl required placeholder="Email" type="text" autoCapitalize="off" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl placeholder="Password" type="password" autoComplete="off" required />
                    </FormGroup>
                    {authError && <small className="text-danger">{authError.displayMessage}</small>}
                  </div>
                }
                legend={
                  <div>
                    <Button type="submit" bsStyle="primary" fill wd>
                      {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Login</span>}
                    </Button>
                    <p style={{ marginTop: "20px" }}>
                      <a href="/#/auth/forgot-password">Forgot Password?</a>
                    </p>
                  </div>
                }
                ftTextCenter
              />
              <div className="signup__terms-container">
                <ControlLabel className="signup__terms-label">
                  By signing in, you agree to our
                  <a
                    className="signup__terms-link"
                    href="https://www.waitly.com/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                  {" and"}
                  <a
                    className="signup__terms-link"
                    href="https://www.waitly.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                </ControlLabel>
              </div>
            </Form>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default LoginPage;
