class PriceHelper {
  private prices: any;
  DEFAULT_PRICES = {
    trialEligible: false,
    premium: {
      monthly: {
        priceFormatted: "$39.99",
        price: 39.99
      },
      annually: {
        priceFormatted: "$399",
        price: 399,
        annualSavings: `$${(39.99 * 12 - 399).toFixed(2)}`
      }
    },
    pro: {
      monthly: {
        priceFormatted: "$99.99",
        price: 99.99
      },
      annually: {
        priceFormatted: "$977",
        price: 977,
        annualSavings: `$${(99.99 * 12 - 977).toFixed(2)}`
      }
    },
    enterprise: {
      monthly: {
        priceFormatted: "from $249",
        price: 249
      },
      annually: {
        priceFormatted: "from $249",
        price: 249
      }
    }
  };

  constructor(prices: any) {
    this.prices = prices;
  }

  getPrices(): any {
    if (this.prices) {
      return this.prices;
    } else {
      return this.DEFAULT_PRICES;
    }
  }

  isTrialEligible(): boolean {
    const p = this.getPrices();
    return p.trialEligible || false;
  }

  // Define the signature for getPrice based on your needs.
  // For example, it might take a product name and return its price.
  getPrice(plan: string, interval: string): string {
    const p = this.getPrices();
    return p[plan][interval].priceFormatted;
  }

  getPriceToday(plan: string, interval: string): string {
    const p = this.getPrices();
    if (this.hasDiscount(plan, interval)) {
      return p[plan][interval].discount.price;
    } else {
      return this.getPrice(plan, interval);
    }
  }

  getTerm(plan: string, interval: string): string {
    const p = this.getPrices();
    return p[plan][interval].discount.term;
  }

  // Define the signature for getSavings based on your needs.
  // For example, it might take two product names and return the price difference.
  getSavings(plan: string, interval: string): string {
    if (plan === "enterprise") {
      return "Monthly";
    }
    const p = this.getPrices();
    if (this.hasDiscount(plan, interval)) {
      return `${p[plan][interval].discount.name} ${p[plan][interval].discount.term}`;
    } else if (interval === "annually") {
      return `Save ${p[plan][interval].annualSavings}`;
    } else {
      return "Monthly";
    }
  }

  hasDiscount(plan: string, interval: string): boolean {
    if (plan === "enterprise") {
      return false;
    }
    const p = this.getPrices();
    return p[plan][interval].discount != undefined;
  }
}

export default PriceHelper;
