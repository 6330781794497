/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import History from "views/History.jsx";
import Charts from "views/Charts.jsx";
import Waitlist from "views/Waitlist";
import LoginPage from "views/Pages/LoginPage";
import RegisterPage from "views/Pages/RegisterPage";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage";
import AcceptInvite from "./views/Pages/AcceptInvite";
import Profile from "components/Profile/Profile";
import Settings from "components/Settings/Settings";
import Upgrade from "components/Upgrade/Upgrade";
import DailyReservations from "components/DailyReservations/DailyReservations";
import Customers from "views/Customers";

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "mdi mdi-speedometer",
    component: Dashboard
  },
  {
    path: "/waitlist",
    layout: "/admin",
    name: "Waitlist",
    icon: "mdi mdi-format-list-numbered",
    component: Waitlist
  },
  {
    path: "/reservations",
    layout: "/admin",
    name: "Reservations",
    icon: "mdi mdi-calendar",
    component: DailyReservations
  },
  {
    path: "/history",
    layout: "/admin",
    name: "History",
    icon: "mdi mdi-history",
    component: History
  },
  {
    path: "/customers",
    layout: "/admin",
    name: "Customers",
    icon: "mdi mdi-account-multiple",
    component: Customers
  },
  {
    path: "/charts",
    layout: "/admin",
    name: "Charts",
    icon: "mdi mdi-chart-timeline-variant",
    component: Charts
  },
  {
    path: "/upgrade",
    layout: "/admin",
    name: "Upgrade",
    icon: "mdi mdi-arrow-up",
    component: Upgrade
  },
  {
    path: "/profile",
    layout: "/admin",
    name: "Profile",
    icon: "mdi mdi-account",
    component: Profile
  },
  {
    path: "/settings",
    layout: "/admin",
    name: "Settings",
    icon: "mdi mdi-cog",
    component: Settings
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "pe-7s-gift",
    views: [
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage
      },
      {
        path: "/accept-invite",
        layout: "/auth",
        name: "Accept Invite",
        mini: "AI",
        component: AcceptInvite
      },
      {
        path: "/register-page",
        layout: "/auth",
        name: "Register",
        mini: "RP",
        component: RegisterPage
      },
      {
        path: "/forgot-password",
        layout: "/auth",
        name: "Forgot Password",
        mini: "FP",
        component: ForgotPasswordPage
      }
    ]
  }
];
export default routes;
