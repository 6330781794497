import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SettingsInput from "./SettingsInput";
import { timezones } from "../../constants/timezones.js";
import { updateBusiness, updateBusinessSettings } from "../../services/profile/profile.service";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import { deleteBusiness } from "../../services/businesses/businesses.service";
import { deleteMessage } from "../../constants/messages";
import MoveUsers from "../MoveUsers/MoveUsers";
import { showOnDashboard } from "configs/access";
import { userIsAdmin } from "configs/access";
import { useSelector } from "react-redux";

const DEFAULT_TIMEZONE = "America/New_York";
const MAX_NAME_LENGTH = 50;

const GeneralSettings = ({ business, user, plan, showDeleteButton, setSelectedBusiness }) => {
  const { register, handleSubmit, errors } = useForm();
  const [moveUsersShowModal, setMoveUsersShowModal] = useState(false);
  const { account } = useSelector(state => state.account || {});
  const [form, setForm] = useState({
    name: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dashboardEnabled: false,
    externalId: ""
  });
  const [loading, setLoading] = useState(false);
  const [infoAlert, setInfoAlert] = useState(null);
  const [businessForDelete, setBusinessForDelete] = useState(null);

  useEffect(() => {
    if (business) {
      setForm({
        name: business.name,
        timezone: business?.settings?.timezone || DEFAULT_TIMEZONE,
        dashboardEnabled: business.dashboardEnabled || false,
        ...(onCheckIfUserIsAdmin() && { externalId: business.externalId || "" })
      });
    }
    // eslint-disable-next-line
  }, [business]);

  const onSubmit = async data => {
    await updateBusiness(business.businessId, {
      ...{
        name: form.name,
        dashboardEnabled: form.dashboardEnabled || false,
        ...(onCheckIfUserIsAdmin() && { externalId: form.externalId })
      },
      ...data
    });
    await updateBusinessSettings(business.businessId, { ...{ timezone: form.timezone }, ...data });
    toastr.success(`Settings updated`);
  };

  const hideInfoAlert = () => {
    setBusinessForDelete(null);
    setInfoAlert(null);
  };

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const validateBusinessName = value => {
    return value.length !== 0 && value.length <= MAX_NAME_LENGTH;
  };

  const onShowDashboardEnabledButton = () => {
    return showOnDashboard(plan, user);
  };

  const onCheckIfUserIsAdmin = () => {
    return userIsAdmin(user);
  };

  const onShowDeleteMessage = () => {
    setInfoAlert(deleteMessage("location"));
    setBusinessForDelete(business);
  };

  const onDeleteCurrentLocation = async () => {
    try {
      await deleteBusiness(businessForDelete.businessId);
      hideInfoAlert();
      setSelectedBusiness();
    } catch (error) {
      setInfoAlert(null);
      if (error.message === "409") {
        setMoveUsersShowModal(true);
      }
    }
  };

  const shouldDisplayExternalId = () => {
    return (
      onCheckIfUserIsAdmin() &&
      business &&
      !business.squareLinked &&
      (account.id === "xj5spEZSQb2wd0nFRKY6" || account.id === "IgBNLUJQqJwa1URhwUAX")
    );
  };

  const onCloseMoveUsersModal = () => {
    setMoveUsersShowModal(false);
  };

  const onMoveUsersToAnotherLocation = async businessToMove => {
    setLoading(true);
    await deleteBusiness(businessForDelete.businessId, businessToMove);
    setSelectedBusiness();
    setLoading(false);
    onCloseMoveUsersModal();
  };

  return (
    <>
      {infoAlert && (
        <SweetAlert title={infoAlert.title} onConfirm={hideInfoAlert}>
          {infoAlert.message}
        </SweetAlert>
      )}
      {businessForDelete && infoAlert && (
        <SweetAlert
          title={infoAlert.title}
          showCancel
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteCurrentLocation}
        >
          {infoAlert.message}
        </SweetAlert>
      )}
      {moveUsersShowModal && (
        <MoveUsers
          closeModal={onCloseMoveUsersModal}
          moveUsers={onMoveUsersToAnotherLocation}
          showMoveUsersModal={moveUsersShowModal}
          loading={loading}
        />
      )}
      <SettingsWrapper
        title={`General ${business ? " - " + business.name : ""}`}
        handleSubmit={handleSubmit(onSubmit)}
        handleDelete={onShowDeleteMessage}
        showDelete={showDeleteButton()}
        buttonStyles={{ maxWidth: 600 }}
      >
        <Row>
          <Col md={9}>
            <SettingsInput
              placeholder={"Enter name"}
              form={form}
              value={form.name}
              enabled={true}
              allowEdit={true}
              name={"name"}
              type={"text"}
              errors={errors}
              label={"Location Name"}
              subtitle={"Your guests may see this name"}
              onChangeValue={onChangeFormField}
              register={register({ required: true, maxLength: 50 })}
              validation={validateBusinessName}
            />
          </Col>
        </Row>
        {business && business.squareLinked && business.squareName && (
          <Row>
            <Col md={9}>
              <SettingsInput
                placeholder={"Enter name"}
                value={business.squareName}
                enabled={true}
                allowEdit={false}
                name={"name"}
                type={"text"}
                errors={errors}
                label={"Square Nickname"}
                subtitle={"Nickname can be changed in your Square Dashboard"}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={9}>
            <SettingsInput
              form={form}
              name={"timezone"}
              type={"select"}
              enabled={true}
              value={form.timezone}
              options={timezones}
              onChangeValue={onChangeFormField}
              errors={errors}
              label={"Timezone"}
              register={null}
            />
          </Col>
        </Row>
        {onShowDashboardEnabledButton() && (
          <Row>
            <Col md={6}>
              <SettingsInput
                form={form}
                name={"dashboardEnabled"}
                type={"switch"}
                enabled={true}
                errors={errors}
                value={form.dashboardEnabled}
                label={"Show on Dashboard"}
                register={null}
                onChangeValue={toggleChecked}
              />
            </Col>
          </Row>
        )}
        {shouldDisplayExternalId() && (
          <>
            <Row>
              <Col md={9}>
                <SettingsInput
                  placeholder={"External ID"}
                  form={form}
                  value={form.externalId}
                  enabled={true}
                  name={"externalId"}
                  type={"text"}
                  errors={errors}
                  label={"External ID"}
                  onChangeValue={onChangeFormField}
                />
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <SettingsInput
                  placeholder={"Waitly ID"}
                  value={business.businessId}
                  enabled={false}
                  name={"waitlyId"}
                  type={"text"}
                  errors={errors}
                  label={"Waitly ID"}
                />
              </Col>
            </Row>
          </>
        )}
        {business && business.squareLinked && (
          <Row>
            <Col md={9}>
              <SettingsInput
                form={form}
                value={form.externalId}
                enabled={true}
                name={"squareBadge"}
                type={"square-badge"}
                label={"Linked to"}
              />
            </Col>
          </Row>
        )}
      </SettingsWrapper>
    </>
  );
};

export default GeneralSettings;
