import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";
import TextMessagesBlock from "./TextMessagesBlock";
import squareLogo from "../../assets/img/square-trans-bg.png";
import SquareColorPicker from "components/Square/SquareColorPicker";
import { useDropzone } from "react-dropzone";
import { BeatLoader } from "react-spinners";

type SettingsInputProps = {
  errors?;
  name;
  link?: any;
  allowEdit?: any;
  label?;
  enabled;
  register?;
  subtitle?: any;
  placeholder?: any;
  onChangeValue;
  type: "text" | "color" | "image-dropzone" | "select" | "switch" | "textarea" | "square-badge";
  options?: { value: string; text: string }[];
  value;
  hideLabel?: boolean;
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
  className?: string;
  onImageUpload?: any;
  clearImage?: any;
  imageLoading?: any;
  onInputClick?: any;
  isNumber?: any;
  removeDefaultStyle?: boolean;
};

const SettingsInput = ({
  errors,
  name,
  link,
  allowEdit,
  label,
  enabled,
  register,
  subtitle,
  placeholder,
  onChangeValue,
  type,
  options,
  value,
  hideLabel,
  style,
  onImageUpload,
  className,
  clearImage,
  imageLoading = false,
  onInputClick,
  isNumber,
  removeDefaultStyle
}: SettingsInputProps) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: "relative",
      marginLeft: 0,
      marginRight: 0
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0
    },
    label: {
      position: "absolute",
      left: value ? 13 : 37,
      color: "white",
      fontSize: 12,
      top: 17
    }
  }));
  const classes = useStyles();
  const dropZoneActiveColor = "#447EF7";

  const { getRootProps, getInputProps, open, acceptedFiles, isDragAccept } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"]
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    disabled: !allowEdit
  });

  React.useEffect(() => {
    if (type === "image-dropzone" && acceptedFiles && acceptedFiles.length) {
      onImageUpload(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const acceptStyle = {
    borderColor: dropZoneActiveColor
  };

  const styleDropZone = React.useMemo(
    () => ({
      ...(isDragAccept && allowEdit ? acceptStyle : {})
    }),
    [isDragAccept]
  );

  const IOSSwitch = withStyles(theme => ({
    root: {
      width: 67,
      height: 32,
      padding: 0,
      margin: "8px 0"
    },
    switchBase: {
      padding: 1,
      top: 1,
      left: 2,
      "&$checked": {
        transform: "translateX(35px)",
        top: 1,
        left: 0,
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "rgb(79, 128, 239)",
          opacity: 1,
          border: "none"
        }
      },
      "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "#9faed0"
      },
      "&$focusVisible $thumb": {
        color: "rgb(79, 128, 239)",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 60 / 2,
      border: "1px solid rgb(193, 193, 193)",
      backgroundColor: "rgb(193, 193, 193)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
    // @ts-ignore
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        checked={value}
        onChange={onChangeValue}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  });

  const renderInput = () => {
    switch (type) {
      case "text":
        return (
          <div className="settings-input-wrapper">
            <input
              name={name}
              className="form-control settings-input"
              type={isNumber ? "number" : "text"}
              placeholder={placeholder}
              autoFocus
              disabled={allowEdit === false || false || !enabled}
              onChange={onChangeValue}
              value={value}
              ref={register}
            />
            {errors && errors[name] && errors[name].type === "required" && (
              <HelpBlock>{label ? `${label + " is required"}` : "Name is required"}</HelpBlock>
            )}
            {errors && errors[name] && errors[name].type === "maxLength" && (
              <HelpBlock> {errors[name].message || "Name length should be less than 50 characters"}</HelpBlock>
            )}
          </div>
        );
      case "color":
        return (
          <div className="settings-input-wrapper" style={{ marginRight: 243 }}>
            <SquareColorPicker
              changeColor={value => onChangeValue(name, value)}
              color={value}
              disabled={allowEdit === false || false}
            />
          </div>
        );
      case "image-dropzone":
        return (
          <div className="settings-input-wrapper">
            {imageLoading ? (
              <div style={{ height: 55, paddingTop: 20 }}>
                {/* @ts-ignore */}
                <BeatLoader size={10} color={dropZoneActiveColor} />
              </div>
            ) : value ? (
              <div className="appearance-image-wrp">
                <img src={value} alt="dropzone" />
                <p className="minus-icon">
                  <i className="mdi mdi-24px mdi-minus-circle" onClick={clearImage} />
                </p>
              </div>
            ) : (
              <div {...getRootProps({ className: "appearance-drop-zone", style: styleDropZone })}>
                <>
                  <input {...getInputProps()} disabled={!allowEdit} />
                  <div>Drop image to upload</div>
                  <div>OR</div>
                </>
                <button type="button" disabled={imageLoading || !allowEdit} onClick={open}>
                  Upload
                </button>
              </div>
            )}
          </div>
        );
      case "select":
        return (
          <div className="settings-input-wrapper">
            <select
              name={name}
              className="form-control settings-input"
              onChange={onChangeValue}
              value={value}
              style={style || {}}
              ref={register}
              disabled={!enabled}
            >
              {options?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            {errors && errors[name] && errors[name].type === "required" && (
              <HelpBlock>{label ? `${label + " is required"}` : "Name is required"}</HelpBlock>
            )}
            {errors && errors[name] && errors[name].type === "maxLength" && (
              <HelpBlock> {errors[name].message || "Name length should be less than 50 characters"}</HelpBlock>
            )}
          </div>
        );
      case "switch":
        return (
          <FormControlLabel
            classes={classes}
            disabled={!enabled}
            control={
              // @ts-ignore
              <IOSSwitch checked={value} name={name} disabled={!enabled} />
            }
            labelPlacement="start"
            label={undefined}
          />
        );
      case "textarea":
        return (
          <TextMessagesBlock
            enabled={enabled}
            hideLabel={hideLabel}
            textareaValue={value}
            name={name}
            register={register}
            errors={errors}
            link={link}
            handleTextareaChange={onChangeValue}
            onClick={onInputClick}
          />
        );
      case "square-badge":
        return (
          <div>
            <img src={squareLogo} alt="Square Logo" style={{ marginBottom: "5px" }} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <FormGroup
      className={"flex settings-form-group " + (className ?? "")}
      style={{
        ...(removeDefaultStyle ? {} : { alignItems: type === "image-dropzone" ? "start" : !subtitle ? "center" : "" }),
        ...style
      }}
      validationState={(errors && !!errors[name] && "error") || null}
    >
      {!hideLabel && (
        <div className="settings-label-wrapper">
          <ControlLabel className="settings-label">{label}</ControlLabel>
          <ControlLabel style={{ color: !enabled && "#CAC9C9" }} className="settings-subtitle">
            {subtitle}
          </ControlLabel>
        </div>
      )}
      {renderInput()}
    </FormGroup>
  );
};

export default SettingsInput;
